var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:" container"},[_c('v-row',{staticStyle:{"width":"100%"}},[_c('v-col',{staticClass:"mt-4",attrs:{"cols":"10","offset-lg":"1","offset-md":"1"}},[_c('Breadcrumbs',{attrs:{"items":[
          {
            text: 'All Staff',
            disabled: false,
            href: '/admin/staffs/all',
          },
          {
            text: _vm.details && _vm.details._id ? _vm.details._id.name : '',
            disabled: true,
            href: '',
          },
          {
            text: !!_vm.$route.query.id ? 'Transactions' : 'Add Staff',
            disabled: true,
            href: !!_vm.$route.query.id
              ? '/admin/staffs/add'
              : '/admin/staffs/all',
          } ]}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1 flex-wrap"},[_c('v-card',{staticClass:"box-shadow-light d-flex flex-row flex-wrap flex-grow-1 pa-3 ma-1"},[_c('div',{staticClass:"d-flex flex-column"},[_c('v-avatar',{attrs:{"color":"rgba(9, 55, 255, 0.21)","size":"60"}},[_c('i',{staticClass:"material-icons-outlined",staticStyle:{"color":"#0937ff !important"}},[_vm._v("account_circle")])])],1),_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex flex-row flex-full flex-wrap"},[_c('h5',{staticClass:"font text-uppercase font-weight-medium font-md mx-3 mt-1 grey--text"},[_vm._v(" "+_vm._s(_vm.details && _vm.details._id ? _vm.details._id.name : '')+" ")])]),_c('div',{staticClass:"d-flex flex-row flex-full flex-wrap"},[(_vm.details && _vm.details._id)?_c('span',{staticClass:"font font-md font-weight-medium ml-3"},[_vm._v("Acc/No.: "+_vm._s(_vm.details._id.accountNumber))]):_vm._e()]),_c('div',{staticClass:"d-flex flex-row flex-full flex-wrap"},[(_vm.details && _vm.details._id)?_c('span',{staticClass:"font font-md font-weight-medium ml-3"},[_vm._v("Phone No.: "+_vm._s(_vm.details._id.phone))]):_vm._e()])])]),_c('v-card',{staticClass:"box-shadow-light d-flex flex-row flex-wrap flex-grow-1 pa-3 ma-1"},[_c('div',{staticClass:"d-flex flex-column"},[_c('v-avatar',{attrs:{"color":"rgba(9, 55, 255, 0.21)","size":"60"}},[_c('i',{staticClass:"material-icons-outlined",staticStyle:{"color":"#0937ff !important"}},[_vm._v("shopping_basket")])])],1),_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex flex-row flex-full flex-wrap"},[_c('h5',{staticClass:"font font-weight-medium font-md mx-3 mt-1 grey--text"},[_vm._v(" Overall Total Transactions ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"vc--verified-icon",attrs:{"small":"","color":"#09c711"}},on),[_vm._v("verified_user")])]}}])},[_c('span',{staticClass:"ft font-weight-medium"},[_vm._v("Verified Suppliers")])])],1),_c('div',{staticClass:"d-flex flex-row flex-full flex-wrap"},[(_vm.details && _vm.details.totalAmount)?_c('span',{staticClass:"font font-size-lg font-weight-bold ml-3"},[_vm._v(_vm._s(_vm._f("currencyFormat")(_vm.details.totalAmount)))]):_c('span',{staticClass:"font font-size-lg font-weight-bold ml-3"},[_vm._v("0")])])])]),(_vm.date)?_c('v-card',{staticClass:"box-shadow-light d-flex flex-row flex-wrap flex-grow-1 pa-3 ma-1"},[_c('div',{staticClass:"d-flex flex-column"},[_c('v-avatar',{attrs:{"color":"rgba(227, 77, 254, 0.22)","size":"60"}},[_c('i',{staticClass:"material-icons-outlined",staticStyle:{"color":"#af06e4 !important"}},[_vm._v("credit_card")])])],1),_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex flex-row flex-full flex-wrap"},[_c('h4',{staticClass:"font font-weight-medium font-md mx-3 mt-1 black--text"},[_vm._v(" Total Transactions for "+_vm._s(_vm.date)+" ")])]),_c('div',{staticClass:"d-flex flex-row flex-full flex-wrap"},[(_vm.details && _vm.details.totalAmountByDate)?_c('span',{staticClass:"font font-weight-bold font-size-lg text-lg ml-3"},[_vm._v(_vm._s(_vm._f("currencyFormat")(_vm.details.totalAmountByDate.totalAmount)))]):_vm._e()])])]):_vm._e()],1)]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-tabs',{staticClass:" grey",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider'),_c('v-tab',{staticClass:" font",attrs:{"href":"#tab-1"}},[_vm._v(" Transactions ")]),_c('v-spacer'),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"box-shadow-light mt-2 mr-2 font text-uppercase font-weight-medium",attrs:{"label":"","close":_vm.date !== null,"color":_vm.date !== null ? 'primary' : 'white'},on:{"click:close":function($event){_vm.date = null}}},'v-chip',attrs,false),on),[_c('i',{staticClass:"material-icons-outlined font-size-md mr-2"},[_vm._v("event")]),_vm._v(" "+_vm._s(_vm.date ? _vm.date : 'Choose Date ')+" ")])]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{staticClass:" font font-weight-medium font-size-sm",on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{attrs:{"value":"tab-1"}},[_c('div',{staticClass:" d-flex flex-column flex-grow-1"}),_c('div',{staticClass:" d-flex flex-column flex-grow-1 mt-2 "},[_c('v-data-table',{staticClass:"box-shadow-light font font-weight-medium font-size-sm",attrs:{"loading":_vm.pageLoading,"headers":_vm.headers,"options":_vm.options,"server-items-length":_vm.pagination.total,"items":_vm.transactions,"footer-props":{
                    itemsPerPageOptions: [10, 20, 30],
                  }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("timestamp")(item.createdAt))+" ")]}},{key:"item.memberId",fn:function(ref){
                  var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","small":"","color":"primary"}},[_c('span',{staticClass:" font font-weight-medium font-sm text-uppercase"},[_vm._v(_vm._s(item.memberId && item.memberId.lastName)+" "+_vm._s(item.memberId && item.memberId.firstName))])])]}},{key:"item.service",fn:function(ref){
                  var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","small":"","color":"primary"}},[_c('span',{staticClass:" font font-weight-medium font-sm text-uppercase"},[_vm._v(_vm._s(item.service))])])]}}])})],1)]),_c('v-tab-item',{attrs:{"value":"tab-2"}})],1)],1)],1)],1)],1),_c('SnackBar',{attrs:{"showSnackBar":_vm.showSnackBar,"message":_vm.message,"status":_vm.status}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }